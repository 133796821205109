var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "Returns",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "c_id",
      "selectable": _vm.selectable,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable
    },
    on: {
      "selectAction": function ($event) {
        return _vm.$emit('selectAction', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "is_opened",
      fn: function ({
        item
      }) {
        return [item.is_opened === 1 ? _c('span', {
          staticClass: "is-open-item open"
        }, [_vm._v("Открыто")]) : _vm._e(), item.is_opened === 0 ? _c('span', {
          staticClass: "is-open-item close"
        }, [_vm._v("Закрыто")]) : _vm._e()];
      }
    }, {
      key: "isSupply",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.isSupply ? "да" : "нет") + " ")];
      }
    }, {
      key: "isRealization",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(item.isRealization ? "да" : "нет") + " ")];
      }
    }])
  }, 'data-table', _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };