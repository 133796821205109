import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_vm.hasOzon && _vm.hasWb ? _c('app-select', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_marketplace_items,
            "fullwidth": ""
          },
          model: {
            value: _vm.marketplace,
            callback: function ($$v) {
              _vm.marketplace = $$v;
            },
            expression: "marketplace"
          }
        }) : _vm._e(), _c('app-select-multi', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_tokens_items
          },
          model: {
            value: _vm.selectedTokens,
            callback: function ($$v) {
              _vm.selectedTokens = $$v;
            },
            expression: "selectedTokens"
          }
        }), _c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.$store.state.connections.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, false, 3360862318)
  }, [_vm._v(" Обзор показателей ")]), _vm.reportSettings ? _c('entity-charts', {
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.$store.state.connections.calendar
    }
  }) : _vm._e(), _c('main-title', [_vm._v(" Аналитические отчеты ")]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings && _vm.tabs_tables_model ? _c(_vm.getTableComponent(_vm.tabs_tables_model.id), {
    key: _vm.tableKey,
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-outline btn-ratings",
    on: {
      "click": function ($event) {
        _vm.$router.push({
          name: _vm.tabs_tables_model.id.firstLetterToUppercase()
        });
      }
    }
  }, [_vm._v(" Полный отчет "), _c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/arrow.svg`),
      "width": "19",
      "height": "18"
    }
  })]), _c('main-title', {
    staticClass: "highlights__cards__title"
  }, [_vm._v(" Инструменты ")]), _c('div', {
    staticClass: "highlights__cards"
  }, [_c('router-link', {
    staticClass: "card",
    attrs: {
      "to": '/products'
    }
  }, [_c('h3', {
    staticClass: "card__title"
  }, [_vm._v(" Импорт себестоимости товаров ")]), _c('img', {
    staticClass: "highlights__cards__image",
    attrs: {
      "src": require("../assets/images/highlights/1.svg"),
      "alt": "Image"
    }
  }), _c('p', {
    staticClass: "highlights__cards__text"
  }, [_vm._v(" Загрузите в систему себестоимость продукции для оптимального расчета прибыльности и анализа лучших товаров для продажи ")])]), _c('router-link', {
    staticClass: "card",
    attrs: {
      "to": '/delivery-calculation'
    }
  }, [_c('h3', {
    staticClass: "card__title"
  }, [_vm._v("Расчет оптимальной поставки")]), _c('img', {
    staticClass: "highlights__cards__image",
    attrs: {
      "src": require("../assets/images/highlights/2.svg"),
      "alt": "Image"
    }
  }), _c('p', {
    staticClass: "highlights__cards__text"
  }, [_vm._v(" Используйте точные алгоритмы оценки спроса и продаж для оптимального расчета кол-ва товаров к будущей поставке на склады маркетплейсов ")])]), _c('router-link', {
    staticClass: "card",
    attrs: {
      "to": '/compare'
    }
  }, [_c('h3', {
    staticClass: "card__title"
  }, [_vm._v("Сравнение периодов")]), _c('img', {
    staticClass: "highlights__cards__image",
    attrs: {
      "src": require("../assets/images/highlights/3.svg"),
      "alt": "Image"
    }
  }), _c('p', {
    staticClass: "highlights__cards__text"
  }, [_vm._v(" Сравните показатели магазина в разных периодах для анализа ключевых трендов ")])]), _vm.without_cost_price_total > 0 ? _c('div', {
    staticClass: "highlights__warning"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/highlights/warning.svg"),
      "alt": "Warning"
    }
  }), _c('p', {
    staticClass: "highlights__warning__text"
  }, [_vm._v(" Не указана себестоимость у " + _vm._s(_vm.without_cost_price_total) + " товаров ")])]) : _vm._e()], 1)], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };